<template>
  <section class="bando-head">
    <h1>Aide</h1>
  </section>

  <div class="container">
    <section class="content">
      <!-- FAQ -->
      <h2>Questions les plus fréquentes</h2>

      <div class="accordion" id="accordion-faq">
        <div class="question">
          <h3>
            <button
              class="btn btn-faq"
              type="button"
              data-toggle="collapse"
              data-target="#Q1"
              aria-expanded="true"
              aria-controls="Q1"
            >
              <i class="fal fa-angle-right"></i>
              Comment prendre un rendez-vous pour un bénéficiaire ?
            </button>
          </h3>
          <div id="Q1" class="collapse show" data-parent="#accordion-faq">
            <div class="inner">
              <p>
                Seul un télé-opérateur peut prendre un rendez-vous. <br />
                Cliquez sur "
                <router-link :to="{ name: 'RdvNew' }">
                  Prendre un rendez-vous
                </router-link>
                " dans la navigation ou sur le
                <router-link :to="{ name: 'Home' }">
                  Tableau de bord
                </router-link>
                .
              </p>
              <p>Un rendez-vous est pris en 3 étapes :</p>
              <ol>
                <li>
                  <strong>
                    Rechercher le bénéficiaire (par Nom, Prénom, Référence
                    bénéficiaire)
                  </strong>
                  <ul>
                    <li>Sélectionnez le bénéficiaire</li>
                    <li>
                      Renseignez son téléphone (si possible), adresse mail (si
                      possible), date de naissance (si possible) et les autres
                      informations nécessaires au rendez-vous
                    </li>
                    <li>Cliquez sur "Valider"</li>
                  </ul>
                </li>
                <li>
                  <strong>Planifier le rendez-vous</strong>
                  <ul>
                    <li>
                      Choisissez le jour du rendez-vous avec le bénéficiaire en
                      visualisant les dates disponibles
                    </li>
                    <li>Cliquez sur la date souhaitée</li>
                    <li>
                      Choisissez le début du rendez-vous avec un des FSE en
                      fonction des créneaux des disponibilités
                    </li>
                    <li>Cliquez sur "Valider"</li>
                  </ul>
                </li>
                <li>
                  <strong>Valider</strong>
                  <ul>
                    <li>
                      Vérifiez les informations du rendez-vous avec le
                      bénéficiaire (Modifier si besoin = retour à l'étape 2)
                    </li>
                    <li>Cliquez sur "Valider"</li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="question">
          <h3>
            <button
              class="btn btn-faq collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#Q2"
              aria-expanded="false"
              aria-controls="Q2"
            >
              <i class="fal fa-angle-right"></i>
              Comment modifier un rendez-vous ?
            </button>
          </h3>
          <div id="Q2" class="collapse" data-parent="#accordion-faq">
            <div class="inner">
              <p>
                Seul un télé-opérateur peut modifier un rendez-vous. <br />
                Cliquez sur "
                <router-link :to="{ name: 'RdvListe' }">
                  Tous les rendez-vous
                </router-link>
                " dans la navigation ou sur le
                <router-link :to="{ name: 'Home' }">
                  Tableau de bord
                </router-link>
                .
              </p>

              <ol>
                <li>
                  <strong>
                    Rechercher le bénéficiaire (par Nom, Prénom, Référence
                    bénéficiaire, ...)
                  </strong>
                  <ul>
                    <li>
                      Sélectionnez le bénéficiaire (<i class="fal fa-eye"></i>)
                      et sur sa fiche, sélectionnez le rendez-vous
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Modifier le rendez-vous</strong>
                  <ul>
                    <li>Cliquez sur le bouton "Modifier"</li>
                    <li>Modifiez le rendez-vous (date, horaire, FSE)</li>
                    <li>Cliquez sur "Valider"</li>
                  </ul>
                </li>
                <li>
                  <strong>Valider</strong>
                  <ul>
                    <li>
                      Vérifiez les informations du rendez-vous (Modifier si
                      besoin = retour à l'étape 2)
                    </li>
                    <li>Cliquez sur "Valider"</li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="question">
          <h3>
            <button
              class="btn btn-faq collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#Q3"
              aria-expanded="false"
              aria-controls="Q3"
            >
              <i class="fal fa-angle-right"></i>
              Comment annuler un rendez-vous ?
            </button>
          </h3>
          <div id="Q3" class="collapse" data-parent="#accordion-faq">
            <div class="inner">
              <p>
                Seul un télé-opérateur peut annuler un rendez-vous. <br />
                Cliquez sur "
                <router-link :to="{ name: 'RdvListe' }">
                  Tous les rendez-vous
                </router-link>
                " dans la navigation ou sur le
                <router-link :to="{ name: 'Home' }">
                  Tableau de bord
                </router-link>
                .
              </p>

              <ol>
                <li>
                  <strong>
                    Rechercher le bénéficiaire (par Nom, Prénom, Référence
                    bénéficiaire, ...)
                  </strong>
                  <ul>
                    <li>
                      Sélectionnez le bénéficiaire (<i class="fal fa-eye"></i>)
                      et sur sa fiche, sélectionnez le rendez-vous
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Annuler le rendez-vous</strong>
                  <ul>
                    <li>Cliquez sur le bouton "Supprimer"</li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="question">
          <h3>
            <button
              class="btn btn-faq collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#Q4"
              aria-expanded="false"
              aria-controls="Q4"
            >
              <i class="fal fa-angle-right"></i>
              Comment contacter un bénéficiaire ?
            </button>
          </h3>
          <div id="Q4" class="collapse" data-parent="#accordion-faq">
            <div class="inner">
              <p>
                Seul un télé-opérateur peut contacter un rendez-vous. <br />
                Cliquez sur "
                <router-link :to="{ name: 'BeneficiairesListe' }">
                  Liste des bénéficiaires
                </router-link>
                " dans la navigation ou sur le
                <router-link :to="{ name: 'Home' }">
                  Tableau de bord
                </router-link>
                .
              </p>

              <ol>
                <li>
                  <strong>
                    Rechercher le bénéficiaire (par Nom, Prénom, Référence
                    bénéficiaire, ...)
                  </strong>
                  <ul>
                    <li>
                      Sélectionnez le bénéficiaire (<i class="fal fa-eye"></i>)
                      et accédez à sa fiche
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="question">
          <h3>
            <button
              class="btn btn-faq collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#Q5"
              aria-expanded="false"
              aria-controls="Q5"
            >
              <i class="fal fa-angle-right"></i>
              Comment modifier les informations d’un bénéficiaire ?
            </button>
          </h3>
          <div id="Q5" class="collapse" data-parent="#accordion-faq">
            <div class="inner">
              <p>
                Seul un télé-opérateur peut modifier un bénéficiaire. <br />
                Cliquez sur "
                <router-link :to="{ name: 'BeneficiairesListe' }">
                  Liste des bénéficiaires
                </router-link>
                " dans la navigation ou sur le
                <router-link :to="{ name: 'Home' }">
                  Tableau de bord
                </router-link>
                .
              </p>

              <ol>
                <li>
                  <strong>
                    Rechercher le bénéficiaire (par Nom, Prénom, Référence
                    bénéficiaire, ...)
                  </strong>
                  <ul>
                    <li>
                      Sélectionnez le bénéficiaire (<i class="fal fa-eye"></i>)
                      et accédez à sa fiche
                    </li>
                    <li>Modifier les informations si besoin</li>
                    <li>Cliquez sur "Valider"</li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="question">
          <h3>
            <button
              class="btn btn-faq collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#Q6"
              aria-expanded="false"
              aria-controls="Q6"
            >
              <i class="fal fa-angle-right"></i>
              Comment contacter un établissement ou un responsable
              d’établissement ?
            </button>
          </h3>
          <div id="Q6" class="collapse" data-parent="#accordion-faq">
            <div class="inner">
              <p>
                Cliquez sur "
                <router-link :to="{ name: 'EtablissementsListe' }">
                  Liste des établissements
                </router-link>
                " dans la navigation ou sur le
                <router-link :to="{ name: 'Home' }">
                  Tableau de bord
                </router-link>
                .
              </p>

              <ol>
                <li>
                  <strong>
                    Rechercher l'établissement (par nom d’établissement, code
                    postal, commune, nom de responsable...)
                  </strong>
                  <ul>
                    <li>
                      Sélectionnez l'établissement (<i class="fal fa-eye"></i>)
                      et accédez à sa fiche
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="question">
          <h3>
            <button
              class="btn btn-faq collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#Q7"
              aria-expanded="false"
              aria-controls="Q7"
            >
              <i class="fal fa-angle-right"></i>
              Comment gérer les zones de vacations pour un établissement ?
            </button>
          </h3>
          <div id="Q7" class="collapse" data-parent="#accordion-faq">
            <div class="inner">
              <p>
                Seul un responsable d’établissement ou un télé-opérateur peut
                contacter créer une zone de vacation.
              </p>

              <p><strong>Pour un responsable d'établissement</strong></p>

              <ol>
                <li>
                  <strong>Sélectionner son établissement</strong>
                  <ul>
                    <li>
                      Sélectionnez l'établissement (<i class="fal fa-eye"></i>)
                      et accédez à sa fiche
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Gérer les zones de vacations</strong>
                  <ul>
                    <li>Cliquez sur "Gérer les zones de vacations"</li>
                    <li>
                      Visualisez à gauche les communes rattachés à
                      l’établissement
                    </li>
                    <li>
                      Cliquez sur le bouton "Ajouter une zone de vacations"
                    </li>
                    <li>Indiquer un nom pour la nouvelle zone de vacations</li>
                    <li>
                      Glissez et déposez la ou les communes que vous souhaitez
                      inclure dans cette zone de vacations
                    </li>
                    <li>
                      Vous pouvez créer plusieurs zones de vacations en même
                      temps et les organiser
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Valider</strong>
                  <ul>
                    <li>Cliquez sur "Valider"</li>
                  </ul>
                </li>
              </ol>

              <p><strong>Pour un télé-opérateur</strong></p>

              <ol>
                <li>
                  <strong>Rechercher et sélectionner un établissement</strong>
                  <ul>
                    <li>
                      Sélectionnez l'établissement (<i class="fal fa-eye"></i>)
                      et accédez à sa fiche
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Gérer les zones de vacations</strong>
                  <ul>
                    <li>Cliquez sur "Gérer les zones de vacations"</li>
                    <li>
                      Visualisez à gauche les communes rattachés à
                      l’établissement
                    </li>
                    <li>
                      Cliquez sur le bouton "Ajouter une zone de vacations"
                    </li>
                    <li>Indiquer un nom pour la nouvelle zone de vacations</li>
                    <li>
                      Glissez et déposez la ou les communes que vous souhaitez
                      inclure dans cette zone de vacations
                    </li>
                    <li>
                      Vous pouvez créer plusieurs zones de vacations en même
                      temps et les organiser
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Valider</strong>
                  <ul>
                    <li>Cliquez sur "Valider"</li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <!-- PDF -->
      <h2>Tutoriels (PDF) à télécharger</h2>

      <div class="pdfs">
        <!-- RDV -->
        <div class="bloc-shadow bloc-pdf" v-if="isOperateur">
          <div class="inner">
            <img src="@/assets/img/dashboard/icon-rdv.png" alt="Rendez-vous" />
            <h3>Les rendez-vous</h3>
            <ul class="list-unstyled">
              <li>Créer un rendez-vous</li>
              <li>Modifier un rendez-vous</li>
              <li>Annuler un rendez-vous</li>
              <li>Exporter les rendez-vous</li>
            </ul>
          </div>
          <a
            href="path/tutoriel-rendez-vous.pdf"
            target="_blank"
            title="Télécharger le tutoriel"
            class="download"
          >
            <i class="fas fa-download"></i>
          </a>
        </div>

        <!-- Bénéficiaires -->
        <div class="bloc-shadow bloc-pdf" v-if="isOperateur">
          <div class="inner">
            <img
              src="@/assets/img/dashboard/icon-beneficiaire.png"
              alt="Bénéficiaires"
            />
            <h3>Les bénéficiaires</h3>
            <ul class="list-unstyled">
              <li>Rechercher un bénéficiaire</li>
              <li>Modifier un bénéficiaire</li>
              <li>Exporter les bénéficiaire</li>
            </ul>
          </div>
          <a
            href="path/tutoriel-beneficiaires.pdf"
            target="_blank"
            title="Télécharger le tutoriel"
            class="download"
          >
            <i class="fas fa-download"></i>
          </a>
        </div>

        <!-- Etablissements -->
        <div class="bloc-shadow bloc-pdf" v-if="isOperateur || isResponsable">
          <div class="inner">
            <img
              src="@/assets/img/dashboard/icon-etablissements.png"
              alt="Etablissements"
            />
            <h3>Les établissements</h3>
            <ul class="list-unstyled">
              <li>Rechercher un établissement</li>
              <li>Gérer les zones de vacations d’un établissement</li>
              <li>Attribuer des FSE à des zones de vacations</li>
            </ul>
          </div>
          <a
            href="path/tutoriel-etablissements.pdf"
            target="_blank"
            title="Télécharger le tutoriel"
            class="download"
          >
            <i class="fas fa-download"></i>
          </a>
        </div>

        <!-- FSE -->
        <div
          class="bloc-shadow bloc-pdf"
          v-if="isOperateur || isResponsable || isFse"
        >
          <div class="inner">
            <img src="@/assets/img/dashboard/icon-fse.png" alt="FSE" />
            <h3>Les FSE d’un établissement</h3>
            <ul class="list-unstyled">
              <li>Agenda des FSE</li>
              <li>Gérer les vacations des FSE d’un établissement</li>
              <li>Les FSE d’un établissement</li>
            </ul>
          </div>
          <a
            href="path/tutoriel-fse.pdf"
            target="_blank"
            title="Télécharger le tutoriel"
            class="download"
          >
            <i class="fas fa-download"></i>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Aide",
  components: {},
  computed: {
    ...mapGetters([
      "getProfile",
      "isProfileLoaded",
      "isAdmin",
      "isFse",
      "isOperateur",
      "isResponsable"
    ])
  }
};
</script>

<style scoped lang="scss">
.content {
  h2 {
    color: var(--navy);
    font-family: var(--font-title);
    font-weight: 600;
    font-size: 2.4rem;
    margin-bottom: 3.5rem;

    @media screen and (min-width: 992px) {
      font-size: 3rem;
    }
  }

  .accordion {
    margin-bottom: 4rem;
  }

  .question {
    border-left: 1rem solid var(--yellow);
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba(black, 0.1);
    margin-bottom: 4rem;

    .inner {
      padding: 1rem 2rem;

      @media screen and (min-width: 992px) {
        padding: 1rem 7.5rem 2rem;
      }
    }

    p,
    li {
      @media screen and (min-width: 992px) {
        font-size: 1.8rem;
      }
    }

    ol {
      padding-left: 3rem;
    }

    p,
    ol,
    ul {
      margin-bottom: 2rem;
    }
  }

  .btn-faq {
    box-shadow: none !important;
    display: block;
    font-size: 1.8rem;
    font-weight: 700;
    padding: 2rem;
    text-align: left;
    width: 100%;

    @media screen and (min-width: 992px) {
      font-size: 2rem;
      padding: 2rem 3rem;
    }

    i {
      font-size: 3rem;
      margin-right: 2rem;
      vertical-align: sub;
      transition: all 0.3s linear;

      @media screen and (min-width: 992px) {
        margin-right: 3rem;
      }
    }

    &[aria-expanded="true"] {
      i {
        transform: rotate(90deg);
      }
    }
  }

  .pdfs {
    @media screen and (min-width: 992px) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1rem;
    }

    .bloc-pdf {
      background: white;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 0 3rem;
      padding: 2rem;
      text-align: center;

      @media screen and (min-width: 992px) {
        flex-basis: calc(25% - 2rem);
        min-width: calc(25% - 2rem);
        margin: 0 1rem 2rem;
      }

      .inner {
        margin-bottom: 2rem;
      }

      h3 {
        background: var(--yellow);
        border-radius: 0.8rem;
        display: block;
        font-size: 1.6rem;
        margin-bottom: 2rem;
        padding: 1rem 1.5rem;
        text-align: center;
      }

      li {
        color: var(--darkgrey);
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 1rem;
      }

      .download {
        color: black;
        display: block;
        font-size: 3rem;
        margin: 0 auto;

        &:hover {
          color: var(--yellow);
        }
      }
    }
  }
}
</style>
